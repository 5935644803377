
import { Row, Col, Card, Image } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState, useMemo, useEffect } from 'react';
import propTypes from 'prop-types';
import { useBlogContext} from '../../contexts/useBlogContext'

const BlogCarousel = ({ size, justify }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const isPostIdPresent = id !== undefined 
    const handlePostClick = useCallback((postId) => navigate(`/blog/post/${postId}`), [navigate]);
      const { posts, loading, error } = useBlogContext();
      const [postsCards, setPostsCards] = useState([]);
      
      const postsMapAll = useMemo(() => posts.map((post) => ({
        id: post.id,
        title: post.title,
          resume: !isPostIdPresent ? post.resume : '',
        cardImgPath: post.cardImgPath
      })), [posts, isPostIdPresent]);
    
      useEffect(() => {
        const postsToCards = postsMapAll.slice(0, 3);
        setPostsCards(postsToCards);
      }, [postsMapAll]);
    
      if (loading) {
        return <p>Cargando...</p>;
      }
      if (error) {
        return <p>Error al cargar los posts</p>;
      }

    const heightSize = {
        small: "270px",
        medium: "370px",
        large: "450px",
    }
    const justifyContent = {
        start: "justify-content-start",
        center: "justify-content-center",
        end: "justify-content-end",
    }

    return (
        <Row
            id="blogRow"
            lg={3}
            className= { `align-content-center mx-lg-0 px-lg-0 px-5 my-1 my-lg-0 d-flex gap-3 gap-lg-2  ${justifyContent[justify]}` }
            style={{ height: heightSize[size] }}
        >
            {postsCards.map(({ id, title, cardImgPath, resume }) => (
                <Col
                    id="blogCol"
                    key={id}
                >
                    <Card
                        id="blogCard"
                        className={`w-100 h-100 ${id % 2 === 0 ? "bg-card-blue-light" : "bg-card-blue-dark" 
                                }`}
                        onClick={() => handlePostClick(id)}
                    >
                        <Card.Body
                            className={`position-relative p-3`}
                        >
                            <Image src={cardImgPath.path} alt={cardImgPath.alt} className='mb-2' width={80} height={90} />
                            <Card.Title className={!isPostIdPresent ? 'card-title-text' : 'card-title-text-post'}>{title}</Card.Title>
                            {resume &&
                                <Card.Text className="pb-3 px-lg-auto card-paragraph-text">
                                    {resume}
                                </Card.Text>}
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default BlogCarousel;

BlogCarousel.propTypes = {
    size: propTypes.oneOf(['small', 'medium', 'large']).isRequired,
    justify : propTypes.oneOf(['start', 'center', 'end']).isRequired,
    // posts: propTypes.arrayOf(
    //     propTypes.shape({
    //         id: propTypes.number.isRequired,
    //         title: propTypes.string.isRequired,
    //         resume: propTypes.string,
    //         cardImgPath: propTypes.shape({
    //             path: propTypes.string.isRequired,
    //             alt: propTypes.string.isRequired
    //         }).isRequired
    //     }),
    // ).isRequired
}
