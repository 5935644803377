import { Row, Col, Card, Button } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';

const stepContentArray = [
  {
    id: 1,
    title: 'Paso 1',
    srcImg: '/images/steps/step1.svg',
    paragraph: 'Completa el formulario de contacto ubicado en la parte superior de esta página o llámanos al siguiente número: 1-870-445-6742.'
  },
  {
    id: 2,
    title: 'Paso 2',
    srcImg: '/images/steps/step2.svg',
    paragraph: 'Recibe las cotizaciones de los diferentes transportistas interesados en brindarte el servicio de transporte.'
  },
  {
    id: 3,
    title: 'Paso 3',
    srcImg: '/images/steps/step3.svg',
    paragraph: 'Evalúa las cotizaciones y elige la más conveniente en términos de precio y servicio. Luego, contrata directamente con la empresa seleccionada.'
  }
];

const titleH1Style = { fontSize: '50px', lineHeight: '50px', fontWeight: '500' }
const Steps = () => {
  const customNavigate = useCustomNavigate();
  return (
    <section className='d-flex align-items-center w-100 bg-green-light-cu-2 p-lg-5 py-3 flex-column' id="steps">
      <div className='container justify-content-between mx-lg-auto mb-lg-5 px-lg-5 d-flex flex-lg-row flex-column'>
        <div className='container p-0 p-lg-auto'>
          <img id='stepsImg' className='rounded-3' src="/images/steps/step-car-ilustration.png" alt="step car ilustration" />
        </div>
        <div className='p-0 px-lg-5 container align-content-center'>
          <h1 style={titleH1Style} className='m-0 mb-lg-5 my-4 text-wrap text-lg-nowrap text-break'>Envía tu vehículo sin complicaciones</h1>
          <Button className='px-5 d-none d-lg-block' variant="primary" onClick={() => customNavigate('/','cotizar')} >Cotizar</Button>
        </div>
      </div>
      <div className='container'>
        <Row id='stepsRow' xs={1} md={3} className='container align-content-center mx-lg-auto mb-lg-5 px-lg-5 d-flex justify-content-between' >
          {stepContentArray.map((card) => (
            <Col id='stepsCol' key={card.id} style={{ width: '32%', height: '45%' }} >
              <Card id='stepsCard' style={{ width: '100%', height: '100%' }}>
                <Card.Body className='none-border px-0 m-lg-0 px-lg-0'>
                  <div className='mb-3'>
                    <img src={card.srcImg} alt={`${card.title} icon`} />
                  </div>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>
                    {card.paragraph}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div className='d-lg-none mt-3 container'>
        <Button variant="primary" onClick={() => customNavigate('/','cotizar')} >Cotizar</Button>
      </div>
    </section>
  );
};

export default Steps;
