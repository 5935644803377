import propTypes from "prop-types";
import parse from 'html-react-parser';

/**
 * A function component that takes an array of paragraph objects
 * and renders a <p> element for each paragraph with the
 * paragraph text as its content.
 *
 * The purpose of this component is to render a series of
 * paragraphs of text. Each paragraph is an object with an
 * id and a text property. The component receives an array
 * of these objects as a prop called body.
 *
 * The component maps over the array of paragraphs and
 * returns a <p> element for each paragraph. The key
 * prop for each <p> element is set to the paragraph's
 * id, which is assumed to be a unique number or string.
 * The className prop is set to "post-p".
 *
 * @param {{ body: { id: number, text: string }[] }} props
 */
const PostInfoParagraph = ({ body }) => {
    return (
        body.map(({ id, text }) => (
            <p key={id} className="post-p">{parse(text)}</p>
        ))
    )
}

export default PostInfoParagraph;

PostInfoParagraph.propTypes = {
    body: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            text: propTypes.string.isRequired
        }),
    ).isRequired
}