import React from 'react';
import { Navbar, Container, Nav, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCustomNavigate } from '../utils/customNavigation';

function Navi() {
  const [expanded, setExpanded] = React.useState(false);
  const customNavigate = useCustomNavigate();
  const navbarClass = expanded ? 'bg-custom-green navbar-mobile-expanded navbar-custom' : 'bg-custom-green navbar-custom';

  return (
    <Navbar
      expand="lg"
      sticky="top"
      className={navbarClass}
      expanded={expanded}
      onToggle={() => setExpanded(expanded => !expanded)}
    >
      <Container className="navbar-container navbar-mobile text-inter w-100 justify-content-between px-lg-5" >
        <Navbar.Brand >
          <a href='/#hero'>
            <Image
              src="/carrosacentroamerica.svg"
              width="65"
              height="50"
              className="d-inline-block align-top"
              alt="Logo TCP"
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
          <Nav className="nav-links">
            <Nav.Link onClick={() => customNavigate('/', 'somos')}>Beneficios</Nav.Link>
            <Nav.Link onClick={() => customNavigate('/', 'steps')}>Procesos</Nav.Link>
            <Nav.Link onClick={() => customNavigate('/', 'review')}>Testimonios</Nav.Link>
            <Nav.Link onClick={() => customNavigate('/blog', 'blogBanner')}>Blog</Nav.Link>

            <Nav.Link className='d-lg-none' onClick={() => customNavigate('/','contact')}>Contactanos</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Button className='px-5 py-3 d-none d-lg-block' variant="outline-light" style={{ fontSize: '0.75rem' }} onClick={() => customNavigate('/', 'contact')}>
          Contacto
        </Button>
      </Container>
    </Navbar>
  );
}

export default Navi;