import { useState, useEffect } from "react";

const useFetchPost = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                const postsResponse = await fetch(`${window.location.origin}/post/post.json`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    cache: 'no-store'
                });

                if (!postsResponse.ok) {
                    throw new Error('No data found');
                }

                const postsData = await postsResponse.json();
                const postDetailsPromises = postsData.map(
                    (post) => post.ref ? fetch(`${window.location.origin}/${post.ref}`).then((res) => res.json()) : Promise.resolve({})
                );

                const postDetails = await Promise.all(postDetailsPromises);
                setData(
                    postsData.map((post, i) => ({
                        ...post,
                        details: postDetails[i]
                    }))
                );
            } catch (error) {
                setError(error.message || "Error al obtener los posts");
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    return { data, error, loading };
}

export default useFetchPost;
