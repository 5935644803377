import { Image, Row, Col, } from 'react-bootstrap';

const titleH1Style = { fontSize: '30px', lineHeight: '35px', fontWeight: '400' }

const Flag = () => {
  return (
    <div className=" text-center text-inter bg-green-light-cu-2 p-5 m-0 flex-column" id='flags'>
      <div className='d-none d-lg-flex justify-content-center gap-4'>
        <Image src="/images/flag1.png" alt="Flag 1" className="custom-img-size" />
        <Image src="/images/flag2.png" alt="Flag 2" className="custom-img-size" />
        <Image src="/images/flag3.png" alt="Flag 3" className="custom-img-size" />
        <Image src="/images/flag5.png" alt="Flag 5" className="custom-img-size" />
      </div>
      <div className='d-lg-none'>
        <Row xs={12} className="mb-4 justify-content-around">
          <Col xs={6} className="px-lg-0 m-0"><Image src="/images/flag1.png" alt="Flag 1" className="custom-img-size" /></Col>
          <Col xs={6} className="px-lg-0 m-0"><Image src="/images/flag2.png" alt="Flag 2" className="custom-img-size" /></Col>
        </Row>
        <Row xs={12} className="mb-4">
          <Col xs={6} className="px-lg-0"><Image src="/images/flag3.png" alt="Flag 3" className="custom-img-size" /></Col>
          <Col xs={6} className="px-lg-0"><Image src="/images/flag5.png" alt="Flag 5" className="custom-img-size" /></Col>
        </Row>
      </div>
      <div className='g-0 mt-4'>
        <h1 style={titleH1Style}>Envío de autos al interior de USA y a cinco <br /> países de Centro América</h1>
      </div>
    </div>
  );
};

export default Flag;
