import "../LogoSlider.css"; 
import { useEffect } from 'react';

export default function Banner() {
    return (
        <section className="d-flex justify-content-center align-items-center w-100 bg-green-light-cu-2 h-50 p-lg-5" id="banner">
            
            <div className="container d-lg-flex flex-column lg:flex-row p-lg-0 justify-content-center justify-content-lg-start align-items-center align-items-lg-start overflow-hidden">
            
                <div className="w-100 d-flex justify-content-center mt-4 justify-content-lg-start">
                    <img src="/images/banner/garlasllc-banner-tittle.svg" height={"35px"} alt="Logo Garlas LLC" className="mb-4" />
                </div>
                <div className="d-flex flex-column gap-3 flex-lg-row gap-lg-5">
                    <div className="d-flex flex-column gap-3 lg:w-50  text-green">
                        <h3 className="fs-1 lg:text-left text-center">Tu aliado más grande en los Estados Unidos para transportar tu
                            vehiculo</h3>
                        <div className="rounded-3 p-lg-3 p-3 h-100 d-flex flex-column justify-content-between bg-green-light-cu">
                            <p className="text-center text-lg-start fs-4 lh-1">
                                Somos una red de empresas especializadas en el transporte de tu
                                auto, moto, camión, bote, yate etc.
                            </p>
                            <p className="fw-semibold d-flex justify-content-center justify-content-lg-end fs-lg-3 fs-2">
                                Tu dinos!
                            </p>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex w-50">
                        <BannerLogos />
                    </div>
                    <div className="d-flex d-lg-none w-100 overflow-hidden">
                        <BannerLogosMobile />
                    </div>
                </div>
            </div>
        </section>
    )
}

const logos = {
    logo_nomanejes: { src: "/images/banner/logo_nomanejes.svg", alt: "Logo Nomanejes" },
    logo_puerto_rico_car_transfer: { src: "/images/banner/logo_puertoricocartransfer.svg", alt: "Logo Puerto Rico Car Transfer" },
    logo_carro_a_centro_america: { src: "/images/banner/logo_carrosacentroamerica.svg", alt: "Logo Carro a Centro America" },
    logo_pr_autotransport: { src: "/images/banner/logo_prautotransport.svg", alt: "Logo PR Autotransport" },
    logo_carmoversus: { src: "/images/banner/logo_carmoversus.svg", alt: "Logo Carmoversus" },
    logo_shipshippersusa: { src: "/images/banner/logo_shipshippersusa.svg", alt: "Logo Shipshippers USA" },
    logo_ratesautotransport: { src: "/images/banner/logo_ratesautotransport.svg", alt: "Logo Rates Autotransport" },
    logo_tucarroporusa: { src: "/images/banner/logo_tucarroporusa.svg", alt: "Logo Tu Carro Por Usa" },
    logo_tcpamerica: { src: "/images/banner/logo_tcpamerica.svg", alt: "Logo TCP America" },
}

const logos_ally = {
    logo_ally_centraldispatch: { src: "/images/banner/logo_ally_centraldispatch.svg", alt: "Logo Ally Central Dispatch" },
    logo_ally_seaboard: { src: "/images/banner/logo_ally_seaboardmarine.svg", alt: "Logo Ally Seaboard" },
    logo_ally_trailerbridge: { src: "/images/banner/logo_ally_trailerbridge.svg", alt: "Logo Ally Trailer Bridge" },
    logo_crowley: { src: "/images/banner/logo_ally_crowley.svg", alt: "Logo Crowley" },
    logo_interport: { src: "/images/banner/logo_ally_interport.svg", alt: "Logo Interport" },
    logo_cmacgm: { src: "/images/banner/logo_ally_cmacgm.svg", alt: "Logo CMACGM" },
}

const logos_payments = {
    logo_visa: { src: "/images/banner/logo_payment_visa.svg", alt: "Logo Visa" },
    logo_mastercard: { src: "/images/banner/logo_payment_mastercard.svg", alt: "Logo Mastercard" },
    logo_americanexpress: { src: "/images/banner/logo_payment_americanexpress.svg", alt: "Logo Paypal" },
}

function BannerLogos() {
    return (
            <div className="d-flex flex-column w-100 gap-3">
                <div className="d-flex justify-content-between w-100">
                    <img src={logos.logo_nomanejes.src} width={"90px"} alt={logos.logo_nomanejes.alt} />
                    <img src={logos.logo_puerto_rico_car_transfer.src} width={"140px"} alt={logos.logo_puerto_rico_car_transfer.alt} />
                    <img src={logos.logo_carro_a_centro_america.src} width={"85px"} alt={logos.logo_carro_a_centro_america.alt} />
                    <img src={logos.logo_pr_autotransport.src} width={"80px"} alt={logos.logo_pr_autotransport.alt} />
                </div>
                <div className="d-flex justify-content-between w-100">
                    <img src={logos.logo_carmoversus.src} height={"40px"} alt={logos.logo_carmoversus.alt} />
                    <img src={logos.logo_shipshippersusa.src} height={"40px"} alt={logos.logo_shipshippersusa.alt} />
                </div>

                <div className="d-flex justify-content-between w-100">
                    <img src={logos.logo_ratesautotransport.src} height={"45px"} alt={logos.logo_ratesautotransport.alt} />
                    <img src={logos.logo_tucarroporusa.src} height={"28px"} alt={logos.logo_tucarroporusa.alt} />
                    <img src={logos.logo_tcpamerica.src} height={"40px"} alt={logos.logo_tcpamerica.alt} />
                </div>

                <div className="d-flex justify-content-between w-100 gap-4">
                    <img src={logos_ally.logo_ally_centraldispatch.src} height={"38px"} alt={logos_ally.logo_ally_centraldispatch.alt} />
                    <img src={logos_ally.logo_ally_seaboard.src} height={"30px"} alt={logos_ally.logo_ally_seaboard.alt} />
                    <img src={logos_ally.logo_ally_trailerbridge.src} height={"30px"} alt={logos_ally.logo_ally_trailerbridge.alt} />
                </div>

                <div className="d-flex justify-content-between align-items-center w-100">
                    <img src={logos_ally.logo_crowley.src} height={"40px"} alt={logos_ally.logo_crowley.alt} />
                    <img src={logos_ally.logo_interport.src} height={"80px"} alt={logos_ally.logo_interport.alt} />
                    <img src={logos_ally.logo_cmacgm.src} height={"20px"} alt={logos_ally.logo_cmacgm.alt} />
                </div>

                <div className="d-flex justify-content-center gap-4 w-100">
                    <img src={logos_payments.logo_visa.src} height={"25px"} alt={logos_payments.logo_visa.alt} />
                    <img src={logos_payments.logo_mastercard.src} height={"35px"} alt={logos_payments.logo_mastercard.alt} />
                    <img src={logos_payments.logo_americanexpress.src} height={"25px"} alt={logos_payments.logo_americanexpress.alt} />
                </div>

                <p className="text-center fs-5 fs-lg-6">Comunícate con alguna de nuestras empresas HOY.</p>
            </div>
    )
}

function BannerLogosMobile() {
    useEffect(() => {
        const logoContainer = document.getElementById("logo-container");
        const logoAllyContainer = document.getElementById("logo-ally-container");
        if (logoContainer) {
            logoContainer.innerHTML += logoContainer.innerHTML;
        }
        if (logoAllyContainer) {
            logoAllyContainer.innerHTML += logoAllyContainer.innerHTML;
        }
    }, []);

    return (
        <div className="d-flex flex-column gap-5 w-100 container py-5">
            <div className="logo-slider overflow-hidden position-relative">
                <div className="logo-track d-inline-block" id="logo-container">
                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                    
                    {
                        Object.values(logos).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                </div>
            </div>
            <div className="logo-slider overflow-hidden position-relative">
                <div className="logo-track d-inline-block" id="logo-ally-container">
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                    {
                        Object.values(logos_ally).map((logo, index) => (
                            <img key={index} src={logo.src} alt={logo.alt} className="mx-2 logo-img" />
                        ))
                    }
                </div>
            </div>
            <div className="d-flex gap-2 justify-content-between">
                <img src={logos_payments.logo_visa.src} alt="Visa" className="payment-logo" />
                <img src={logos_payments.logo_mastercard.src} alt="Mastercard" className="payment-logo" />
                <img src={logos_payments.logo_americanexpress.src} alt="American Express" className="payment-logo" />
            </div>
            <div className="text-center">
                <p className="fs-5">Comunícate con alguna de nuestras empresas HOY.</p>
            </div>
        </div>
    );
}